<template>
    <b-container v-if="$store.state.hot.employers.length" id="hot_employers" class="section">
        <b-row align-v="start">
            <b-col cols="12" lg="1" />

            <b-col cols="12" lg="10">
                <div class="subsection">
                    <div class="view">
                        <h2 class="section_title">
                            {{ $t("components.hotEmployers.title") }}
                        </h2>

                        <carousel
                            :dots="true"
                            :margin="16"
                            :nav="true"
                            :responsive="{
                                0: {
                                    items: 1,
                                },
                                576: {
                                    items: 2,
                                },
                                768: {
                                    items: 3,
                                },
                                992: {
                                    items: 4,
                                },
                                1200: {
                                    items: 5,
                                },
                            }"
                            class="employers theme-pracevcr"
                        >
                            <div v-for="employer in $store.state.hot.employers" :key="employer.id" class="employer">
                                <router-link :to="{ name: 'inzeraty', query: { company: employer.id } }">
                                    <b-img :fluid="true" :src="employer.logo" class="employer_image" />

                                    <p class="employer_count">
                                        {{ jobCount(employer.job_count) }}
                                    </p>
                                </router-link>
                            </div>
                        </carousel>
                    </div>
                </div>
            </b-col>

            <b-col cols="12" lg="1" />
        </b-row>
    </b-container>
</template>

<script>
const carousel = () => window && window !== undefined ? import("vue-owl-carousel") : null;

export default {
    components: {
        carousel,
    },
    data() {
        return {
            isNuxtReady: false,
        };
    },
    mounted() {
        if (process.browser) {
            window.onNuxtReady(() => {
                this.isNuxtReady = true;
                this.init();
            });
        }
    },
    methods: {
        init() {
            this.loadData();
        },
        loadData() {
            this.$store.dispatch("hot/indexHotEmployers");
        },
        jobCount(jobCount) {
            return `${this.$tc("jobs.count", jobCount)}`;
        },
    },
};
</script>
