<template>
    <div class="job">
        <div class="job_view">
            <b-row align-v="center">
                <b-col class="d-none d-lg-block" cols="12" lg="2">
                    <b-link :to="{ name: 'inzerat', params: { id: job.id, slug: job.slug } }">
                        <b-img :fluid="true" :src="job.company.logo" class="job_image" />
                    </b-link>
                </b-col>

                <b-col cols="12" lg="5">
                    <h3 class="job_title">
                        <b-link :to="{ name: 'inzerat', params: { id: job.id, slug: job.slug } }">
                            {{ job.title }}
                        </b-link>
                    </h3>

                    <p class="job_paragraph company_place">
                        <span v-if="job.company" class="company">
                            {{ job.company.name }}
                        </span>

                        <span v-if="job.address" class="place">
                            <font-awesome-icon :icon="['fa', 'map-marker-alt']" />
                            {{ job.address }}
                        </span>
                    </p>

                    <p class="job_paragraph price_position">
                        <span v-if="job.salary_formatted" class="price">
                            {{ job.salary_formatted }}
                        </span>

                        <span v-if="job.employment" class="position">
                            {{ job.employment.name }}
                        </span>
                    </p>
                </b-col>

                <b-col cols="12" lg="5">
                    <p class="job_paragraph reactions">
                        {{ reactions() }}
                    </p>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    methods: {
        reactions() {
            const answerCount = this.job.answer_count;

            if (answerCount < 3) {
                return this.$t("jobs.listing.reactions.beFirst");
            } else if (answerCount < 5) {
                return this.$t("jobs.listing.reactions.lessThanFive");
            } else {
                return this.$t("jobs.listing.reactions.more", {
                    count: answerCount,
                });
            }
        },
    },
};
</script>
