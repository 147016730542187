<template>
    <b-container id="hot_jobs" class="section">
        <b-row align-v="center">
            <b-col cols="12" lg="1" />

            <b-col cols="12" lg="10">
                <div class="subsection">
                    <h2 class="section_title">
                        {{ $t("components.hotjobs.latestAds") }}
                    </h2>
                </div>
            </b-col>

            <b-col cols="12" lg="1" />
        </b-row>

        <b-row align-v="start">
            <b-col cols="12" lg="1" />

            <b-col cols="12" lg="10">
                <div class="subsection">
                    <b-nav id="workfields_list">
                        <b-nav-item
                            :active="$store.state.hot.paramsJobs.workfield === null"
                            @click="
                                $store.dispatch('hot/setHotJobsWorkfield', {
                                    value: null,
                                })
                            "
                        >
                            Vše
                        </b-nav-item>

                        <b-nav-item
                            v-for="(workfield, i) in $store.state.hot.workfields"
                            :key="i"
                            :active="$store.state.hot.paramsJobs.workfield === workfield.id"
                            @click="
                                $store.dispatch('hot/setHotJobsWorkfield', {
                                    value: workfield.id,
                                })
                            "
                        >
                            {{ workfield.name }}
                        </b-nav-item>
                    </b-nav>
                </div>

                <div class="subsection">
                    <div id="jobs">
                        <Job v-for="(job, i) in $store.state.hot.jobs" :key="i" :job="job" />
                    </div>
                </div>
            </b-col>

            <b-col cols="12" lg="1" />
        </b-row>
    </b-container>
</template>

<script>
import Job from "~/components/Jobs/Job";

export default {
    components: {
        Job,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loadData();
        },
        loadData() {
            this.$store.dispatch("hot/indexHotJobs");

            this.$store.dispatch("hot/indexHotWorkfields");
        },
        jobCount(jobCount) {
            return `${this.$tc("jobs.count", jobCount)}`;
        },
    },
};
</script>
