<template>
    <div id="index" class="content">
        <HotJobs />

        <HotEmployers />
    </div>
</template>

<script>
import HotJobs from "~/components/HotJobs";
import HotEmployers from "~/components/HotEmployers";

export default {
    components: {
        HotJobs,
        HotEmployers,
    },
    layout: "with_search_hot",
    head() {
        return {
            title: this.$t("pages.index.title"),
        };
    },
    created() {
        this.$store.dispatch("jobs/resetSearch")
    }
};
</script>
