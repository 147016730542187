var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.hot.employers.length)?_c('b-container',{staticClass:"section",attrs:{"id":"hot_employers"}},[_c('b-row',{attrs:{"align-v":"start"}},[_c('b-col',{attrs:{"cols":"12","lg":"1"}}),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","lg":"10"}},[_c('div',{staticClass:"subsection"},[_c('div',{staticClass:"view"},[_c('h2',{staticClass:"section_title"},[_vm._v("\n                        "+_vm._s(_vm.$t("components.hotEmployers.title"))+"\n                    ")]),_vm._v(" "),_c('carousel',{staticClass:"employers theme-pracevcr",attrs:{"dots":true,"margin":16,"nav":true,"responsive":{
                            0: {
                                items: 1,
                            },
                            576: {
                                items: 2,
                            },
                            768: {
                                items: 3,
                            },
                            992: {
                                items: 4,
                            },
                            1200: {
                                items: 5,
                            },
                        }}},_vm._l((_vm.$store.state.hot.employers),function(employer){return _c('div',{key:employer.id,staticClass:"employer"},[_c('router-link',{attrs:{"to":{ name: 'inzeraty', query: { company: employer.id } }}},[_c('b-img',{staticClass:"employer_image",attrs:{"fluid":true,"src":employer.logo}}),_vm._v(" "),_c('p',{staticClass:"employer_count"},[_vm._v("\n                                    "+_vm._s(_vm.jobCount(employer.job_count))+"\n                                ")])],1)],1)}),0)],1)])]),_vm._v(" "),_c('b-col',{attrs:{"cols":"12","lg":"1"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }